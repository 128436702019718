import * as React from "react"
import tw from "twin.macro"
import Modal from "../Modal/Registration"
import { CheckRegistrationProps } from "./CheckRegistration.d"
import Form from "./Form"

const Primary: React.FC<CheckRegistrationProps> = () => {

  return (
    <Modal
      blue={true}
      open={true}
      modalCss={[tw`w-screen lg:max-w-7xl hidden lg:flex`]}
    >
      <Form css={[tw`mx-auto px-36 py-16 bg-red-300 max-w-4xl `]} />
    </Modal>
  )
}

export default Primary
