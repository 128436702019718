import { PageProps } from "gatsby"
import CheckRegistration from "../components/CheckRegistration"
import * as React from "react"
import Seo from "../components/SEO"


const CheckRegistrationPage: React.FC<PageProps> = () => {
  
  return (
    <>
    <Seo title="Check Registration" />

    <CheckRegistration.Primary input={{}} label={{}} />
    <CheckRegistration.Mobile input={{}} label={{}} />
    </>
  )
}

export default CheckRegistrationPage