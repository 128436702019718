import * as React from "react"
import tw from "twin.macro"
import { CheckRegistrationProps } from "./CheckRegistration.d"
import Form from "./Form"

const Mobile: React.FC<CheckRegistrationProps> = () => {
  return (
    <div css={[tw`visible lg:hidden`]}>
      <Form
        css={[
          tw`py-8 px-5 bg-red-300 flex flex-col fixed z-50 bottom-0 w-full`,
        ]}
      />
    </div>
  )
}

export default Mobile
