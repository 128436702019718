import * as React from "react"
import tw from "twin.macro"
import { useState } from "react"
import ButtonGroup from "../Buttons/ButtonGroup"
import { navigate } from "gatsby"
import { isBrowser } from "../../helpers"

const Form: React.FC = ({ ...remainingProps }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [email, checkEmail] = useState("")
  const [error, setError] = useState<string>(null)

  const updateForm = (e: any) => {
    checkEmail(e.target.value)
  }

  
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const emailLowerCase = email?.toLowerCase()
    checkEmail(emailLowerCase)
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(email)){
        setIsSubmitting(true)
        fetch(
          `${process.env.GATSBY_API_ROOT_URL}/auth/check?Email=${encodeURIComponent(
            email
          )}`
        ).then(data => {
          // Avoid failed builds due to window not existing
          if (isBrowser) {
            if (data.status == 404) {
              navigate("/sign-up", {
                state: {
                  email,
                },
              })
            } else {
              navigate("/login", {
                state: {
                  email,
                },
              })
            }

          }
        
      })
    } else {
      setError("Invalid email. Please try again.")
    }
  }

  return (
    <form {...remainingProps} onSubmit={handleSubmit}>
      <h6 css={[tw`text-white text-center pb-8`]}>
        Let's check and see if you have an ALEX account
      </h6>
      <div
        className="input-container"
        css={[tw`relative mx-auto flex flex-col pb-8 w-72`]}
      >
        <input
          className="input"
          type="email"
          placeholder="Email Address"
          value={email}
          name="email"
          onChange={updateForm}
          required
        />
        <label htmlFor="email">Email Address</label>
        {error && <p css={[tw`text-white text-center mt-4`]}>{error}</p>}
      </div>
      <ButtonGroup
        buttons={[
          {
            value: "Next",
            onClick: handleSubmit,
            dark: true,
            disabled: isSubmitting,
            loading: isSubmitting,
            eventName: "click_account_check",
            eventMessage: "Store email address and button click value",
          },
          {
            value: "Cancel",
            to: "/",
            secondary: true,
            disabled: isSubmitting,
          },
        ]}
      />
    </form>
  )
}

export default Form
